<div *ngIf="!!data">
    <mat-table [dataSource]="data" class="fd-table">
      <ng-container matColumnDef="brandName" class="fd-table__cell">
        <mat-header-cell *matHeaderCellDef class="fd-table__header"> Bandeira </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <img [class]="element.brandName" [src]="getFlagImageURL(element.brandName)" [class]="'fd-table__flag-image-'+element.brandName+' fd-table__flag-image'" [alt]="element.brandName+'flag'">

        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="creditFee" class="fd-table__cell">
        <mat-header-cell *matHeaderCellDef class="fd-table__header"> Crédito </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.creditFee/100 | percent : '1.2-3'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="debitFee" class="fd-table__cell">
        <mat-header-cell *matHeaderCellDef class="fd-table__header"> Débito </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.debitFee/100 | percent : '1.2-3' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="creditFeeLessSixInstallment" class="fd-table__cell">
        <mat-header-cell *matHeaderCellDef class="fd-table__header"> Parcelamento em até 6x </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.creditFeeLessSixInstallment/100 | percent : '1.2-3'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="creditFeeGreaterSixInstallment" class="fd-table__cell">
        <mat-header-cell *matHeaderCellDef class="fd-table__header"> Parcelamento mais de 6x </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.creditFeeGreaterSixInstallment/100 | percent : '1.2-3'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="installmentPlan" class="fd-table__cell">
        <mat-header-cell *matHeaderCellDef class="fd-table__header"> Crediário </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.installmentPlan/100 | percent : '1.2-3'}} </mat-cell>
      </ng-container>

      <mat-header-row class="fd-table__header-row" *matHeaderRowDef="columns"></mat-header-row>
      <mat-row class="fd-table__row" *matRowDef="let row; columns: columns"></mat-row>
    </mat-table>
  </div>
