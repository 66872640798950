import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBase } from "../form-base";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CnaeAndMccResponseDTO, CnaeAndMccSearchTableDTO } from "../simulation-economical-group/models/cnae-and-mcc-response-dto.model";
import { MatTableDataSource } from "@angular/material/table";
import { FdFieldConfigs } from "src/app/shared/shared-components.module";
import { ScrollService } from "../../services/internal/scroll/scroll.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { InputType } from "src/app/shared/fd-form-components/fd-input/fd-input.component";
import { MatSort } from "@angular/material/sort";
import { FdSelectConfig, Item } from "src/app/shared/fd-form-components/fd-select/fd-select.component";
import { CnaeService } from "../../services/external/cnae/cnae.service";
import { Messages } from "../../messages/order.messages";
import { DialogService } from "../../services/internal/dialog/dialog.service";

@Component({
    selector: 'cnae-and-mcc-search',
    templateUrl: './cnae-and-mcc-search.component.html',
    styleUrls: ['./cnae-and-mcc-search.component.scss']
  })
export class CnaeAndMccSearch extends FormBase implements OnInit {

    dataSource: MatTableDataSource<CnaeAndMccSearchTableDTO>;

    displayedColumns: string[] = ['cnae','mcc'];

    public filterOptions: [];

    private paginator: MatPaginator;
    private sort: MatSort;

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();

      }

      @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();


      }


    @Output() changePage = new EventEmitter<PageEvent>();
 //   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   // @ViewChild(MatSort) sort: MatSort;

    formGroup: UntypedFormGroup = this.createFormGroup();
    fields: FdFieldConfigs = this.createFields();
    option: string;

    sizeItems = 15;
    totalPages = 0;
    pageNumber = 0;
    hasValue = false;
    previousSizeItems = 0;
    defaultSizeItems = 50;

    constructor(
        private formBuilder: UntypedFormBuilder,
        protected scrollService: ScrollService,
        private cnaeService: CnaeService,
        private dialogService: DialogService
    ){
        super(scrollService);
    }

    setDataSourceAttributes() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

    ngOnInit() {

        this.formControls.mcc.setValue('');
        this.formControls.cnae.setValue('');

        this.configureOptions();

    }

    getMccAndCnaeList(){
        const cnaeFilter = this.option === 'cnae' ? this.formControls.cnae.value : '';
        const mccFilter = this.option === 'mcc' ? this.formControls.mcc.value : '';

        if(cnaeFilter === '' && mccFilter === ''){
          this.dialogService.openDialog(Messages.MCC_AND_CNAE_SEARCH_VALIDATION);
          return;
        }

        this.cnaeService.getCnaeAndMccInfo(cnaeFilter,mccFilter).toPromise().then((res) => {
            if (res.response.length > 0 ){
                this.hasValue = true;
              }
              this.dataSource = new MatTableDataSource(this.formatResponse(res.response));
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.totalPages = res.totalItens;
              this.pageNumber = res.page;
        });
    }

    protected formatResponse(response: CnaeAndMccResponseDTO[]): CnaeAndMccSearchTableDTO[]{
       const formatedResponse = new Array();
        response.forEach((res)=>{
            const tableDTO = new CnaeAndMccSearchTableDTO();
            tableDTO.mcc = res.merchantTariffDescription;
            tableDTO.cnae = res.cnaeNumber + ' - ' + res.cnaeDescription;
            formatedResponse.push(tableDTO);
        })


        return formatedResponse;
    }

    protected createFormGroup(): UntypedFormGroup {
        return this.formBuilder.group({
            mcc: ['',Validators.required],
            cnae: ['',Validators.required],
            filterOptions: [""]

        });
    }

    createFields(): FdFieldConfigs {
        return{
            mcc: {
                label: 'Digite um MCC',
                controlName: 'mcc',
                type: InputType.TEL,
                isNumber: true,
                messages: {
                    required: 'Informe o MCC'
                  }
            },
            cnae: {
                label: 'Digite um CNAE',
                controlName: 'cnae',
                type: InputType.TEL,
                isNumber: true,
                messages: {
                    required: 'Informe o CNAE'
                  }
            },
            filterOptions: {
                controlName: 'filterOptions',
                items: this.filterOptions,
                messages: {
                  required: "Informe uma opção",
                },
              }
        }
    }

    get formControls(): { [key: string]: AbstractControl } {
        return this.formGroup.controls;
      }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  changeMccOrCnaeInput(value: string){
    this.formControls.mcc.setValue('');
    this.formControls.cnae.setValue('');
    this.option = value;
  }

  configureOptions(){
    this.option = 'cnae';

    const mcc = new Item();
    const cnae = new Item();

    mcc.label =  'MCC';
    mcc.value = 'mcc';
    mcc.selected = false;

    cnae.label = 'CNAE';
    cnae.value = 'cnae';
    cnae.selected = false;
    (this.fields.filterOptions as FdSelectConfig).items = [cnae,mcc];
}

}
